<template>
    <div class="content-discovery-menu-builder">
        <RadioButtonGroup
            :key="itemTypeKey"
            label="Select Item Type"
            name="item-type"
            is-form-field-styled
            is-grouped
            is-button-styled
            :options="$options.menuTypes"
            :value="data.menuType"
            :errors="errors.menuType"
            @input="onInputMenuType"
        />

        <Component
            :is="menuComponent"
            v-if="menuComponent"
            :errors="errors"
            :data="data"
            @input="$emit('input-menu-item-property', $event)"
        />

        <Dialog
            show-confirm-button
            :is-dialog-visible="showDialog"
            confirm-button-text="Yes, switch item type"
            @onClose="dialogCancel"
            @onClickOutside="dialogCancel"
            @confirmButtonOnClick="dialogConfirm"
        >
            <template #header> This will clear the configuration for this item. </template>
            <template #body> Are you sure? </template>
        </Dialog>
    </div>
</template>

<script>
import RadioButtonGroup from '@/components/forms/RadioButtonGroup';
import { ContentDiscoveryMenuItemType as MenuType } from '@/enums';
import menuTypes from '@/json/contentDiscoveryMenuItemTypes';
import Dialog from '@/components/ui/Dialog';

const componentMap = {
    [MenuType.ConvoStream]: () => import('./ContentDiscoveryConvoStream'),
    [MenuType.Convo]: () => import('./ContentDiscoveryConvo'),
    [MenuType.Channel]: () => import('./ContentDiscoveryChannel'),
    [MenuType.Custom]: () => import('./ContentDiscoveryCustom'),
};

export default {
    name: 'ContentDiscoveryMenuBuilder',
    components: {
        RadioButtonGroup,
        Dialog,
    },
    props: {
        data: {
            type: Object,
            default: () => ({}),
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data() {
        return {
            showDialog: false,
            itemTypeKey: 1,
        };
    },
    computed: {
        menuComponent() {
            return componentMap[this.data.menuType] || null;
        },
    },
    created() {
        this.$options.menuTypes = menuTypes;
    },
    methods: {
        onInputMenuType(type) {
            this.emitMenuTypeFn = () => this.$emit('input-menu-type', type);
            if (!this.menuComponent) {
                return this.emitMenuTypeFn();
            }
            this.showDialog = true;
        },
        dialogConfirm() {
            this.emitMenuTypeFn();
            this.showDialog = false;
        },
        dialogCancel() {
            this.itemTypeKey += 1; // Force re-render of HTML
            this.showDialog = false;
        },
    },
};
</script>
