<template>
    <div>
        <ErrorMessage v-if="errors && errors.length > 0" class="m-b-2">
            {{ errors[0] }}
        </ErrorMessage>

        <slot name="header" />

        <Tabs
            :is-draggable="isMediumScreen"
            :show-add-button="tabs.length < tabsMaxCount"
            :active-tab="activeTab"
            :add-button-text="addButtonText"
            @addButtonOnClick="$emit('addTab')"
            @onChange="activeTab = $event"
            @onDragEnd="reorderTabs"
        >
            <Tab
                v-for="(tab, index) in tabs"
                :key="index"
                :name="tab.tabName || (index + 1).toString()"
                :is-pinned="isTabPinned(index)"
            >
                <TabActions
                    :index="index"
                    :tabs-length="tabs.length"
                    :is-pinned="isTabPinned(index)"
                    :tab-item-name="tabItemName"
                    :next-is-pinned="isTabPinned(index + 1)"
                    :show-pin-action="showPinAction"
                    @moveTab="moveTabOnClick"
                    @pin="pinOnClick"
                    @delete="deleteTabOnClick"
                />
                <slot :data="tab" :index="index" />
            </Tab>
        </Tabs>

        <slot v-if="tabs.length === 0" name="noTabs" />

        <Dialog
            show-confirm-button
            :is-dialog-visible="deleteConfirmIsOpen"
            @onClose="deleteConfirmIsOpen = false"
            @closeOnEscapeEvent="deleteConfirmIsOpen = false"
            @onClickOutside="deleteConfirmIsOpen = false"
            @confirmButtonOnClick="deleteTabConfirmOnConfirm($event)"
        >
            <template #header> Are you sure you want to delete this tile? </template>
        </Dialog>
    </div>
</template>

<script>
import Dialog from '@/components/ui/Dialog';
import ErrorMessage from '@/components/ui/ErrorMessage';
import Tabs from '@/components/ui/Tabs';
import Tab from '@/components/ui/Tab';
import TabActions from '@/components/ui/TabActions';
import cssVariables from '@styles/abstracts/_variables.layout.scss';

let mediaQueryList;

export default {
    name: 'Tabset',
    components: {
        Dialog,
        ErrorMessage,
        Tabs,
        Tab,
        TabActions,
    },
    props: {
        tabs: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: Array,
            default: () => [],
        },
        tabsMaxCount: {
            type: Number,
            required: true,
        },
        isLastTabPinned: {
            type: Boolean,
            required: true,
        },
        addButtonText: {
            type: String,
            default: 'Add +',
        },
        tabItemName: {
            type: String,
            default: 'tile',
        },
        showPinAction: {
            type: Boolean,
            default: true,
        },
        selectedTab: {
            type: Number,
            default: 0,
        },
    },
    data() {
        return {
            activeTab: this.selectedTab,
            deleteConfirmIsOpen: false,
            deletingTabIndex: undefined,
            isMediumScreen: false,
        };
    },
    watch: {
        selectedTab(value) {
            this.activeTab = value;
        },
    },
    created() {
        const medium = cssVariables['media-queries-medium'].slice(1, -1);
        mediaQueryList = matchMedia(medium);

        if (mediaQueryList.matches) {
            this.isMediumScreen = true;
        }

        mediaQueryList.addListener(this.setIsMediumScreen);
    },
    destroyed() {
        mediaQueryList.removeListener(this.setIsMediumScreen);
    },
    methods: {
        isTabPinned(index) {
            const isLast = index === this.tabs.length - 1;

            return isLast && this.isLastTabPinned;
        },
        reorderTabs(order) {
            const activeTabIndex = this.activeTab;
            const activeTabNewIndex = order.indexOf(activeTabIndex);
            this.$emit('reorderTabs', order);
            this.activeTab = activeTabNewIndex;
        },
        moveTabOnClick(direction, index) {
            let tabIndexes = this.tabs.map((item, index) => index);
            let swapItemIndex;

            switch (direction) {
                case 'left':
                    swapItemIndex = index - 1;
                    break;
                case 'right':
                    swapItemIndex = index + 1;
                    break;
            }

            [tabIndexes[index], tabIndexes[swapItemIndex]] = [
                tabIndexes[swapItemIndex],
                tabIndexes[index],
            ];
            this.reorderTabs(tabIndexes);
        },
        pinOnClick(index) {
            const isLast = index === this.tabs.length - 1;
            let pinLast = false;

            if (isLast && !this.isLastTabPinned) {
                pinLast = true;
            } else if (!isLast) {
                let tabIndexes = this.tabs.map((item, index) => index);
                tabIndexes.splice(index, 1);
                tabIndexes.push(index);
                this.reorderTabs(tabIndexes);
                pinLast = true;
            }

            this.$emit('pinTab', index, pinLast);
        },
        deleteTabOnClick(index) {
            this.deletingTabIndex = index;
            this.deleteConfirmIsOpen = true;
        },
        deleteTabConfirmOnConfirm() {
            this.$emit('deleteTab', this.deletingTabIndex);
            this.activeTab = 0;
            this.deletingTabIndex = undefined;
            this.deleteConfirmIsOpen = false;
        },
        setIsMediumScreen(event) {
            if (event.matches) {
                this.isMediumScreen = true;
            } else {
                this.isMediumScreen = false;
            }
        },
    },
};
</script>
