<template>
    <div>
        <ContentDiscoveryList
            :list="menuList"
            :can-add-menu="canAddMenu"
            @click-menu="onLoadMenu"
            @delete-menu="onDeleteMenu"
            @set-default-menu="onSetDefaultMenu"
            @create-new-menu="onCreateMenu"
        />

        <FormOverlay
            title="Content Discovery Menu"
            :confirm-button-text="confirmButtonText"
            size="wide"
            :is-loading="isLoading"
            :show-overlay="showOverlay"
            @close="onCloseOverlay"
            @confirm="onSaveMenu"
        >
            <ContentDiscoveryMenu
                :menu="menu"
                :menu-items="menuItems"
                :errors="errors"
                @input-menu-property="updateMenuProperty"
                @add-item="addMenuItem"
                @reorder-items="reorderItems"
                @input-menu-type="setMenuType"
                @input-menu-item-property="updateMenuItemProperty"
                @delete-item="deleteMenuItem"
            />
        </FormOverlay>

        <Dialog
            show-confirm-button
            :is-dialog-visible="showDialog"
            confirm-button-text="Yes, close"
            @onClose="onDialogCancel"
            @onClickOutside="onDialogCancel"
            @closeOnEscapeEvent="onDialogCancel"
            @confirmButtonOnClick="onDialogConfirm"
        >
            <template #header> Unsaved changes </template>
            <template #body> You have unsaved changes, are you sure you want to close? </template>
        </Dialog>
    </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import ContentDiscoveryMenu from './ContentDiscoveryMenu';
import ContentDiscoveryList from './ContentDiscoveryList';
import FormOverlay from '@/components/ui/FormOverlay';
import Dialog from '@/components/ui/Dialog';
import { LoadingFlag } from '@/store/enums';
import config from '@/config';

const MAX_MENU_COUNT = config.featureToggle.contentDiscoveryMultipleMenus ? 10 : 1;

export default {
    name: 'ContentDiscovery',
    components: {
        Dialog,
        FormOverlay,
        ContentDiscoveryMenu,
        ContentDiscoveryList,
    },
    props: {
        channel: {
            type: Object,
            required: true,
        },
    },
    data() {
        return {
            showOverlay: false,
            showDialog: false,
        };
    },
    computed: {
        ...mapState('contentDiscoveryMenu', ['menu', 'menuItems', 'menuList', 'errors']),
        ...mapGetters('contentDiscoveryMenu', ['isValid', 'hasChanges']),
        confirmButtonText() {
            if (this.hasChanges) {
                return 'Save changes';
            }
            return 'Finish';
        },
        isLoading() {
            return this.$wait.is([
                LoadingFlag.ContentDiscoveryMenuGetOne,
                LoadingFlag.ContentDiscoveryMenuCreate,
                LoadingFlag.ContentDiscoveryMenuUpdate,
            ]);
        },
        canAddMenu() {
            return this.menuList.length < MAX_MENU_COUNT;
        },
    },
    created() {
        this.loadMenuList();
    },
    methods: {
        ...mapActions('contentDiscoveryMenu', [
            'addMenuItem',
            'setMenuType',
            'reorderItems',
            'updateMenuProperty',
            'updateMenuItemProperty',
            'deleteMenuItem',
            'saveMenu',
            'getMenuList',
            'clearMenu',
            'loadMenu',
            'deleteMenu',
            'validate',
            'setDefaultMenu',
        ]),
        onCloseOverlay() {
            if (this.hasChanges) {
                this.showDialog = true;
            } else {
                this.showOverlay = false;
            }
        },
        onDialogCancel() {
            this.showDialog = false;
        },
        onDialogConfirm() {
            this.showDialog = false;
            this.showOverlay = false;
        },
        async onSaveMenu() {
            await this.validate();
            if (!this.isValid) {
                return;
            }
            if (this.hasChanges) {
                await this.saveMenu(this.channel.id);
                this.loadMenuList();
            }
            this.showOverlay = false;
        },
        async onLoadMenu(id) {
            await this.loadMenu(id);
            this.showOverlay = true;
        },
        onCreateMenu() {
            this.clearMenu();
            this.showOverlay = true;
        },
        async onDeleteMenu(id) {
            await this.deleteMenu({ menuId: id, channelId: this.channel.id });
            this.loadMenuList();
        },
        async onSetDefaultMenu(menu) {
            await this.setDefaultMenu({ menuId: menu.id, channelId: this.channel.id });
            this.$toaster.add(`Default content discovery menu changed to '${menu.name}'`, {
                type: 'success',
            });
        },
        loadMenuList() {
            this.getMenuList(this.channel.id);
        },
    },
};
</script>
