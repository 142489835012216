<template>
    <section class="card">
        <slot />
    </section>
</template>

<script>
export default {
    name: 'SCard',
};
</script>

<style lang="scss" scoped>
.card {
    padding: 2rem;
    border-radius: 4px;
    border: solid 1px $color-athens-gray;
    background-color: $color-white;
}
</style>
