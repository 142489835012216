<template>
    <div class="tabs-component" :class="{ 'tabs-component--compressed': options.isCompressed }">
        <ul role="tablist" class="tabs-component-tabs">
            <li
                v-for="(tab, i) in tabs"
                v-show="tab.isVisible"
                :key="i"
                :class="{ 'is-active': tab.isActive, 'is-disabled': tab.isDisabled }"
                class="tabs-component-tab"
                role="presentation"
            >
                <a
                    :aria-controls="tab.hash"
                    :aria-selected="tab.isActive"
                    :href="tab.hash"
                    class="tabs-component-tab-a"
                    role="tab"
                    @click="selectTab(tab.hash, $event)"
                    v-html="tab.header"
                ></a>
            </li>
        </ul>
        <div class="tabs-component-panels">
            <slot />
        </div>
    </div>
</template>

<script>
import expiringStorage from './expiringStorage';

const DEFAULT_OPTIONS = {
    useUrlFragment: false,
    useCaching: true,
    defaultTabHash: null,
    isCompressed: false,
};

export default {
    name: 'STabs',
    props: {
        cacheLifetime: {
            type: Number,
            default: 5,
        },
        options: {
            type: Object,
            required: false,
            default: () => DEFAULT_OPTIONS,
        },
    },
    data: () => ({
        tabs: [],
        activeTabHash: '',
        activeTabIndex: 0,
        lastActiveTabHash: '',
    }),
    computed: {
        storageKey() {
            return `vue-tabs-component.cache.${window.location.host}${window.location.pathname}`;
        },
    },
    created() {
        this.tabs = this.$children;
    },
    mounted() {
        if (this.findTab(window.location.hash)) {
            this.selectTab(window.location.hash);
            return;
        }
        const previousSelectedTabHash = this.options.useCaching
            ? expiringStorage.get(this.storageKey)
            : null;
        if (this.findTab(previousSelectedTabHash)) {
            this.selectTab(previousSelectedTabHash);
            return;
        }
        if (
            this.options.defaultTabHash !== null &&
            this.findTab('#' + this.options.defaultTabHash)
        ) {
            this.selectTab('#' + this.options.defaultTabHash);
            return;
        }
        if (this.tabs.length) {
            this.selectTab(this.tabs[0].hash);
        }
    },
    methods: {
        findTab(hash) {
            return this.tabs.find((tab) => tab.hash === hash);
        },
        selectTab(selectedTabHash, event) {
            // See if we should store the hash in the url fragment.
            if (event && !this.options.useUrlFragment) {
                event.preventDefault();
            }
            const selectedTab = this.findTab(selectedTabHash);
            if (!selectedTab) {
                return;
            }
            if (selectedTab.isDisabled) {
                event.preventDefault();
                return;
            }
            if (this.lastActiveTabHash === selectedTab.hash) {
                this.$emit('clicked', { tab: selectedTab });
                return;
            }
            this.tabs.forEach((tab) => {
                tab.isActive = tab.hash === selectedTab.hash;
            });
            this.$emit('changed', { tab: selectedTab });
            this.activeTabHash = selectedTab.hash;
            this.activeTabIndex = this.getTabIndex(selectedTabHash);
            this.lastActiveTabHash = this.activeTabHash = selectedTab.hash;
            if (this.options.useCaching) {
                expiringStorage.set(this.storageKey, selectedTab.hash, this.cacheLifetime);
            }
        },
        setTabVisible(hash, visible) {
            const tab = this.findTab(hash);
            if (!tab) {
                return;
            }
            tab.isVisible = visible;
            if (tab.isActive) {
                // If tab is active, set a different one as active.
                tab.isActive = visible;
                this.tabs.every((tab, index, array) => {
                    if (tab.isVisible) {
                        tab.isActive = true;
                        return false;
                    }
                    return true;
                });
            }
        },
        getTabIndex(hash) {
            const tab = this.findTab(hash);
            return this.tabs.indexOf(tab);
        },
        getTabHash(index) {
            const tab = this.tabs.find((tab) => this.tabs.indexOf(tab) === index);
            if (!tab) {
                return;
            }
            return tab.hash;
        },
        getActiveTab() {
            return this.findTab(this.activeTabHash);
        },
        getActiveTabIndex() {
            return this.getTabIndex(this.activeTabHash);
        },
    },
};
</script>

<style lang="scss" scoped>
.tabs-component-tabs {
    border: solid 1px $input-border-color;
    border-radius: 20px;
    margin-bottom: 5px;

    @include media-query(medium) {
        border: 0;
        align-items: stretch;
        display: flex;
        justify-content: flex-start;
        margin-bottom: -1px;
    }
}

.tabs-component-tab {
    position: relative;
    color: $tabs-inactive-text-color;
    font-size: $font-size-base;
    font-weight: $font-weight-bold;
    margin-right: 0;
    list-style: none;

    &:hover {
        color: $tabs-hover-text-color;
    }

    &.is-active {
        color: $tabs-active-text-color;

        @include media-query(medium) {
            border-top-color: $tabs-active-tint-color;
            border-bottom: solid 1px $bg-color;
            z-index: 2;

            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: 0;
                width: 100%;
                height: 2px;
                background-color: $tabs-active-tint-color;
            }
        }
    }

    @include media-query(medium) {
        background-color: $bg-color;
        border: solid 1px $input-border-color;
        border-radius: 4px 4px 0 0;
        margin-right: 8px;
    }
}

.tabs-component-tab .tabs-component-tab .tabs-component-tab .tabs-component-tab.is-disabled * {
    color: $tabs-hover-text-color;
    cursor: not-allowed !important;
}

.tabs-component-tab-a {
    align-items: center;
    color: inherit;
    display: flex;
    padding: 10px 20px;
    text-decoration: none;
}

.tabs-component-panels {
    padding: 20px 0;

    @include media-query(medium) {
        background-color: $bg-color;
        border: solid 1px $input-border-color;
        border-radius: 0 20px 20px 20px;
        padding: 32px 20px;
    }
}

.tabs-component--compressed {
    .tabs-component-tab {
        font-size: $font-size-xs;

        @include media-query(medium) {
            margin-right: 4px;
        }
    }

    .tabs-component-tab-a {
        padding: 10px;
    }
}
</style>
