import { ContentDiscoveryMenuItemType } from '@/enums';

export default [
    { value: ContentDiscoveryMenuItemType.ConvoStream, name: 'Convo Stream', icon: 'convo-stream' },
    { value: ContentDiscoveryMenuItemType.Convo, name: 'Convo', icon: 'convo' },
    {
        value: ContentDiscoveryMenuItemType.Channel,
        name: 'Channel',
        icon: 'channels',
    },
    { value: ContentDiscoveryMenuItemType.Custom, name: 'Custom', icon: 'custom' },
];
