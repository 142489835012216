<template>
    <Draggable
        v-model="localList"
        class="list-group"
        :group="group"
        :ghost-class="ghostClass"
        @start="isDragging = true"
        @end="isDragging = false"
    >
        <transition-group type="transition" name="list" tag="div" class="list-group-animation">
            <slot />
        </transition-group>
    </Draggable>
</template>

<script>
import Draggable from 'vuedraggable';

export default {
    name: 'DraggableListGroup',
    components: {
        Draggable,
    },
    props: {
        value: {
            required: true,
            type: Array,
        },
        group: {
            type: String,
            default: undefined,
        },
        ghostClass: {
            type: String,
            default: 'list-group-item-ghost',
        },
    },
    computed: {
        localList: {
            get() {
                return this.value;
            },
            set(localList) {
                this.$emit('input', localList);
            },
        },
    },
};
</script>

<style scoped lang="scss">
.list-group {
    display: flex;
    flex-direction: column;
}

.list-group-animation:empty {
    padding: 2rem 1rem;
    text-align: center;
}

.list-group-animation:empty:before {
    font-size: 14px;
    content: 'Drag convos here';
}

.list-group,
.list-group-animation {
    height: 100%;
}

/deep/ .list-move {
    transition: transform 400ms;
}

/deep/ div {
    cursor: pointer;
}
</style>
