<template>
    <div class="page-layout-container">
        <div class="page-layout-main">
            <ErrorMessage v-if="!channelIsValid" ref="errorMessage" size="large">
                Incomplete fields
            </ErrorMessage>

            <STabs :options="{ useUrlFragment: true, isCompressed: true }" @changed="onTabChanged">
                <STab name="Channel Attributes">
                    <div class="channel-attributes">
                        <div class="channel-attributes__left">
                            <Input
                                id="name"
                                type="text"
                                name="name"
                                label="Name"
                                :value="channelDetails.channel.name"
                                :errors="channelDetails.errors.name"
                                :max="152"
                                :show-remaining-char="true"
                                @input="(value) => onInput('name', value)"
                            />
                            <Input
                                id="slug"
                                type="text"
                                name="slug"
                                label="Slug"
                                :disabled="slugIsDisabled"
                                :prefix-text="`${baseUrl}/channels/`"
                                :has-prefix="true"
                                :value="channelDetails.channel.slug"
                                :errors="channelDetails.errors.slug"
                                @input="(value) => onInput('slug', value)"
                            />

                            <Input
                                id="description"
                                type="textarea"
                                name="description"
                                label="Description"
                                :value="channelDetails.channel.description"
                                :errors="channelDetails.errors.description"
                                @input="(value) => onInput('description', value)"
                            />

                            <Input
                                id="welcome-message"
                                type="textarea"
                                name="welcome-message"
                                label="Welcome Message"
                                :value="channelDetails.channel.welcomeMessage"
                                :errors="channelDetails.errors.welcomeMessage"
                                :max="640"
                                :show-remaining-char="true"
                                @input="(value) => onInput('welcomeMessage', value)"
                            />

                            <ColorPicker
                                id="channel-color"
                                name="channel-color"
                                class="color-picker"
                                :color-options="[
                                    '#ff6d4a',
                                    '#f9af7d',
                                    '#fccc7f',
                                    '#34c5d2',
                                    '#6e89de',
                                    '#a682de',
                                    '#8593a7',
                                ]"
                                :default-color="defaultColor"
                                :max-colors="10"
                                :value="channelDetails.channel.color"
                                :errors="channelDetails.errors.color"
                                @input="(value) => onInput('color', value)"
                            />
                            <Input
                                id="sharingTitle"
                                type="text"
                                name="sharingTitle"
                                label="Sharing Title"
                                :value="channelDetails.channel.sharingTitle"
                                :errors="channelDetails.errors.sharingTitle"
                                @input="(value) => onInput('sharingTitle', value)"
                            />
                            <CopyText
                                v-if="showWidgetSnippet"
                                name="widget-snippet"
                                label="Widget HTML embed"
                                :value="widgetSnippet"
                            />

                            <Toggle
                                id="show-member-count"
                                label="Show subscriber count"
                                class="m-b-1"
                                :value="channelDetails.channel.showMemberCount"
                                @input="(value) => onInput('showMemberCount', value)"
                            />

                            <div v-if="channelDetails.channel.id > 0">
                                <Toggle
                                    id="channel-status-toggle"
                                    :label="channelStatusToggleText"
                                    :value="channelStatusToggleOn"
                                    @input="onChannelStatusToggle"
                                />
                            </div>
                        </div>
                        <div class="channel-attributes__right">
                            <div class="m-b-3">
                                <ImageUploaderButton
                                    show-preview
                                    input-style="green"
                                    class="channel-image m-b-1"
                                    label="Portrait (5:7)"
                                    :aspect-ratio="5 / 7"
                                    :button-label="
                                        channelDetails.channel.portraitImage || 'Select image'
                                    "
                                    :value="channelDetails.channel.portraitImage"
                                    :errors="channelDetails.errors.portraitImage"
                                    @input="(value) => onInput('portraitImage', value)"
                                />

                                <ImageUploaderButton
                                    show-preview
                                    input-style="green"
                                    class="channel-image m-b-1"
                                    label="Landscape (16:9)"
                                    :aspect-ratio="16 / 9"
                                    :button-label="
                                        channelDetails.channel.landscapeImage || 'Select image'
                                    "
                                    :value="channelDetails.channel.landscapeImage"
                                    :errors="channelDetails.errors.landscapeImage"
                                    @input="(value) => onInput('landscapeImage', value)"
                                />

                                <ImageUploaderButton
                                    show-preview
                                    input-style="green"
                                    class="channel-image"
                                    label="Square"
                                    :aspect-ratio="1"
                                    :button-label="
                                        channelDetails.channel.squareImage || 'Select image'
                                    "
                                    :value="channelDetails.channel.squareImage"
                                    :errors="channelDetails.errors.squareImage"
                                    @input="(value) => onInput('squareImage', value)"
                                />
                            </div>

                            <Input
                                id="tagline"
                                type="text"
                                name="tagline"
                                label="Tag Line"
                                :value="channelDetails.channel.tagLine"
                                :errors="channelDetails.errors.tagLine"
                                @input="(value) => onInput('tagLine', value)"
                            />

                            <Input
                                id="sharingDescription"
                                type="text"
                                name="sharingDescription"
                                label="Sharing Description"
                                :value="channelDetails.channel.sharingDescription"
                                :errors="channelDetails.errors.sharingDescription"
                                @input="(value) => onInput('sharingDescription', value)"
                            />

                            <FormFieldWrapper
                                :errors="channelDetails.errors.topics"
                                for-id="topic"
                                class="m-b-2"
                            >
                                <AutoCompleteList
                                    name="topic"
                                    class="form-field-autocompletelist"
                                    title="topic"
                                    key-ref="externalId"
                                    :label="`Topics: ${channelDetails.channel.topics.length}/5`"
                                    :errors="channelDetails.errors.topics"
                                    :selected-items="channelDetails.channel.topics"
                                    :items="topicSearchResult"
                                    :get-badge="getTopicBadge"
                                    @query="$emit('onSearchTopic', $event)"
                                    @update="onInput('topics', $event)"
                                />
                            </FormFieldWrapper>

                            <Tags
                                placeholder="Add tag"
                                label="Tags"
                                :value="channelDetails.channel.tags"
                                :autocomplete-items="tags"
                                :errors="channelDetails.errors.tags"
                                :add-from-paste="true"
                                :separators="[' ', ';']"
                                @autocomplete="$emit('autocomplete', $event)"
                                @input="(value) => onInput('tags', value)"
                            />

                            <div class="flex m-b-1">
                                <Checkbox
                                    id="isFeatured"
                                    label="Featured"
                                    :value="channelDetails.channel.isFeatured"
                                    @input="(value) => onInput('isFeatured', value)"
                                />
                                <Checkbox
                                    id="isStandalone"
                                    class="m-l-2"
                                    label="Standalone Channel"
                                    :value="channelDetails.channel.isStandalone"
                                    @input="(value) => onInput('isStandalone', value)"
                                />
                            </div>

                            <CheckboxGroup
                                show-label
                                label="Platforms"
                                :options="mappedPlatforms"
                                :selected-options="mappedSelectedPlatforms"
                                :errors="channelDetails.errors.platforms"
                                @input="(value) => onPlatformsChange(value)"
                            />
                        </div>
                    </div>

                    <div v-if="hasFacebookPlatform" class="channel-attributes-fb">
                        <h1 class="m-b-2">Facebook settings</h1>
                        <Input
                            id="fbPageId"
                            type="text"
                            name="fbPageId"
                            label="Page ID"
                            :value="channelDetails.channel.fbPageId"
                            :errors="channelDetails.errors.fbPageId"
                            @input="(value) => onInput('fbPageId', value)"
                        />

                        <Input
                            id="fbAppId"
                            type="text"
                            name="fbAppId"
                            label="App ID"
                            :value="channelDetails.channel.fbAppId"
                            :errors="channelDetails.errors.fbAppId"
                            @input="(value) => onInput('fbAppId', value)"
                        />

                        <Input
                            id="fbAccessToken"
                            type="text"
                            name="fbAccessToken"
                            label="Access Token"
                            :value="channelDetails.channel.fbAccessToken"
                            :errors="channelDetails.errors.fbAccessToken"
                            @input="(value) => onInput('fbAccessToken', value)"
                        />

                        <Input
                            id="fbVerifyToken"
                            type="text"
                            name="fbVerifyToken"
                            label="Verify Token"
                            class="m-b-2"
                            disabled
                            :has-margin-bottom="false"
                            :value="channelDetails.channel.fbVerifyToken"
                            :errors="channelDetails.errors.fbVerifyToken"
                            @input="(value) => onInput('fbVerifyToken', value)"
                        />
                        <Button
                            v-if="channelDetails.channel.id > 0"
                            type="button"
                            has-border
                            size="small"
                            :is-loading="$wait.is(LoadingFlag.ChannelSetupFacebook)"
                            @click="$emit('setupFacebook')"
                        >
                            Setup Facebook
                        </Button>
                    </div>
                </STab>

                <STab
                    v-if="channelDetails.channel && channelDetails.channel.id !== 0"
                    name="Content Discovery Menu"
                >
                    <ContentDiscovery :channel="channelDetails.channel" />
                </STab>

                <STab name="Email Variables">
                    <EmailVariables v-model="emailLocale" />
                </STab>

                <STab name="Landing Page">
                    <landing-page
                        :data="channelDetails.channel.landingPage"
                        @propertyChange="$emit('landingPagePropertyChange', $event)"
                    />
                </STab>

                <STab v-if="showEmailAutomation" name="Email Automation">
                    <email-automation
                        :data="channelDetails.channel.convoSchedule"
                        :timezone="channelDetails.channel.timezoneIdentifier"
                    />
                </STab>

                <STab v-if="showYougovPreferences" name="YouGov Preferences">
                    <YougovPreferences
                        :welcome-flows="welcomeFlows"
                        :show-welcome-chat-preference="showWelcomeChatPreference"
                        :preferences="channelDetails.channel.yougovPreferences"
                        @onGetConvoQuery="$emit('onGetConvoQuery', $event)"
                        @onPreferencesUpdate="$emit('onUpdateYougovPreferences', $event)"
                    />
                </STab>
            </STabs>
        </div>

        <Dialog
            show-confirm-button
            :is-dialog-visible="changeChannelStatusDialogIsOpen"
            :is-loading="$wait.is(LoadingFlag.ChannelChangeStatus)"
            @onClose="onChannelStatusDialogCancel"
            @onClickOutside="onChannelStatusDialogCancel"
            @closeOnEscapeEvent="onChannelStatusDialogCancel"
            @confirmButtonOnClick="onChannelStatusDialogSaveButtonClick($event)"
        >
            <template #header> Disable channel </template>
            <template #body>
                Disabling the channel will make it unavailable for subscribers. <br />
                Do you want to continue?
            </template>
        </Dialog>

        <Dialog
            show-confirm-button
            :is-dialog-visible="confirmDialogIsOpen"
            :is-loading="isSaving"
            @onClose="$emit('confirmDialogOnClose')"
            @onClickOutside="$emit('confirmDialogOnClose')"
            @closeOnEscapeEvent="$emit('confirmDialogOnClose')"
            @confirmButtonOnClick="$emit('confirmDialogSaveButtonClick')"
        >
            <template #header> Some changes have been made to the channel. </template>
            <template #body> Do you want to save these changes? </template>
        </Dialog>
    </div>
</template>

<script>
import ImageUploaderButton from '@/components/forms/ImageUploaderButton';
import { STabs, STab } from '@/components/Tabs';
import Button from '@/components/ui/Button';
import {
    Toggle,
    Checkbox,
    Tags,
    Input,
    CheckboxGroup,
    CopyText,
    FormFieldWrapper,
    AutoCompleteList,
} from '@/components/forms';
import ColorPicker from '@/components/ui/ColorPicker';
import Dialog from '@/components/ui/Dialog';
import ErrorMessage from '@/components/ui/ErrorMessage';
import EmailVariables from '@/components/ui/EmailVariables';
import ContentDiscovery from '@/components/ContentDiscovery/ContentDiscovery';
import LandingPage from '@/components/LandingPage';
import EmailAutomation from '@/components/EmailAutomation/EmailAutomation';
import YougovPreferences from '@/components/ChannelCard/YougovPreferences.vue';
import { ChannelStatus } from '@/store/enums/channelStatus.enum.ts';
import { ButtonStyle } from '@/store/enums/buttonStyle.enum.ts';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import { UserRoles, ConvoTypes, BadgeType } from '@/store/enums';
import config from '@/config';
import getWidgetSnippet from '@/json/widgetSnippet';
import { mapState } from 'vuex';
export default {
    name: 'ChannelCard',
    components: {
        YougovPreferences,
        EmailVariables,
        Tags,
        ImageUploaderButton,
        ColorPicker,
        Input,
        Checkbox,
        CheckboxGroup,
        Toggle,
        Dialog,
        ErrorMessage,
        ContentDiscovery,
        Button,
        LandingPage,
        EmailAutomation,
        STabs,
        STab,
        CopyText,
        FormFieldWrapper,
        AutoCompleteList,
    },
    props: {
        channelDetails: {
            type: Object,
            required: true,
        },
        categories: {
            type: Array,
            required: true,
        },
        platforms: {
            type: Array,
            required: true,
        },
        tags: {
            type: Array,
            required: true,
        },
        channelIsValid: {
            type: Boolean,
            required: true,
        },
        channelModelHasChanges: {
            type: Boolean,
            required: true,
        },
        navIsOpened: {
            type: Boolean,
            required: true,
        },
        isSaving: {
            type: Boolean,
            required: true,
        },
        confirmDialogIsOpen: {
            type: Boolean,
            required: true,
        },
        baseUrl: {
            type: String,
            default: config.baseUrl,
        },
        defaultColor: {
            type: String,
            default: undefined,
        },
        topicSearchResult: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            ButtonStyle,
            ChannelStatus,
            LoadingFlag,
            ConvoTypes,
            changeChannelStatusDialogIsOpen: false,
            channelStatusToggleOn: false,
            yougovPreferencesRoles: [UserRoles.Admin, UserRoles.Editor, UserRoles.Yougov],
            userRoles: [],
            showWelcomeChatPreference: config.featureToggle.channelWelcomeChatPreferenceEnabled,
        };
    },
    computed: {
        ...mapState('convoList', ['welcomeFlows']),
        widgetSnippet() {
            return getWidgetSnippet({
                appKey: this.channelDetails.tenantData.appKey,
                appSecret: this.channelDetails.tenantData.appSecret,
                appCode: this.channelDetails.tenantData.appCode,
                channel: this.channelDetails.channel.slug,
            });
        },
        showWidgetSnippet() {
            return (
                Object.keys(this.channelDetails.tenantData).length &&
                config.featureToggle.widgetSnippet
            );
        },
        emailLocale: {
            get() {
                return this.$store.state.channel.channelDetails.channel.emailLocale;
            },
            set(locale) {
                this.$store.dispatch('channel/setEmailLocale', locale);
            },
        },
        mappedCategories() {
            if (this.categories) {
                return this.categories.map((cat) => {
                    return { id: cat.id, value: cat.name };
                });
            }
            return [];
        },
        mappedPlatforms() {
            if (this.platforms) {
                return this.platforms.map((plat) => {
                    return { id: plat.code, name: plat.name };
                });
            }
            return [];
        },
        mappedSelectedPlatforms() {
            if (this.channelDetails.channel.platforms) {
                return this.channelDetails.channel.platforms.map((plat) => {
                    return { id: plat.code, name: plat.name };
                });
            }
            return [];
        },
        hasFacebookPlatform() {
            if (this.channelDetails.channel.platforms) {
                return this.channelDetails.channel.platforms.some(
                    (plat) => plat.code === 'facebook',
                );
            }
            return false;
        },
        channelStatusToggleText() {
            return `Channel ${
                this.channelDetails.channel.status === ChannelStatus.Active
                    ? 'Activated'
                    : 'Deactivated'
            }`;
        },
        showEmailAutomation() {
            const schedule = this.channelDetails.channel.convoSchedule;
            return (
                schedule.scheduledConfig.length ||
                schedule.selectedConvos.length ||
                schedule.availableConvos.length
            );
        },
        slugIsDisabled() {
            return !config.featureToggle.editableSlug;
        },
        showYougovPreferences() {
            const canAccess = this.userRoles.some((role) =>
                this.yougovPreferencesRoles.includes(role),
            );
            return canAccess && config.featureToggle.channelYougovPreferencesEnabled;
        },
    },
    watch: {
        channelDetails: {
            handler: function (newValue) {
                this.channelStatusToggleOn =
                    newValue.channel.status === ChannelStatus.Active ? true : false;
            },
            immediate: true,
            deep: true,
        },
    },
    created() {
        this.userRoles = this.$store.getters['auth/userRoles'];
    },
    methods: {
        getTopicBadge(topic) {
            if (topic?.id) {
                return null;
            }
            return { label: 'new', type: BadgeType.Neutral };
        },
        onCategoryChange(value) {
            const category = this.mappedCategories.find((item) => item.id === value);
            const id = category.id;
            this.onInput('categoryId', id);
        },
        onPlatformsChange(selectedPlatforms) {
            this.onInput(
                'platforms',
                selectedPlatforms.map((o) => ({ code: o.id, name: o.name })),
            );
        },
        onChannelStatusToggle() {
            this.channelStatusToggleOn = !this.channelStatusToggleOn;
            if (!this.channelStatusToggleOn) {
                this.changeChannelStatusDialogIsOpen = true;
            } else {
                const status = this.channelStatusToggleOn
                    ? ChannelStatus.Active
                    : ChannelStatus.Inactive;
                this.onInput('status', status);
            }
        },
        onChannelStatusDialogSaveButtonClick() {
            const status = this.channelStatusToggleOn
                ? ChannelStatus.Active
                : ChannelStatus.Inactive;
            this.onInput('status', status);
            this.changeChannelStatusDialogIsOpen = false;
        },
        onChannelStatusDialogCancel() {
            this.channelStatusToggleOn = !this.channelStatusToggleOn;
            this.changeChannelStatusDialogIsOpen = false;
        },
        onInput(key, value) {
            this.$emit('change', { key, value });
        },
        animateErrorMessage() {
            this.$refs.errorMessage.animate();
        },
        onTabChanged({ tab }) {
            if (tab.id === 'broadcasts') {
                this.$emit('getBroadcasts', {
                    channelCode: this.channelDetails.channel.code,
                    page: 1,
                    size: this.numberOfBroadcastRows,
                });
            }
        },
    },
};
</script>

<style scoped lang="scss">
.channel-attributes {
    @include media-query(medium) {
        display: flex;
    }

    &__left,
    &__right {
        @include media-query(medium) {
            flex: 0 0 50%;
            min-width: 0;
            max-width: 100%;
        }
    }

    &__left {
        @include media-query(medium) {
            padding-right: 10px;
        }
    }

    &__right {
        @include media-query(medium) {
            padding-left: 10px;
        }
    }
    .color-picker {
        width: 100%;
    }
}

.page-layout-aside-fixed-container {
    top: $header-height + $layout-top-space;
}

.save-container {
    height: 100%;
    display: flex;
    align-items: flex-end;
    padding-bottom: 100px;
}

.info-box {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
}

.form-field-autocompletelist {
    margin: -1px;
}
</style>

<style lang="scss">
$yg-preferences-info-highlighted: #31caa8 !default;

.yg-preferences-info .tooltip-inner {
    font-size: $font-size-xs;
}

.item-highlighted {
    color: $yg-preferences-info-highlighted;
    font-weight: $font-weight-bold;
    font-style: italic;
}
</style>
