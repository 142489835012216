<template>
    <section class="yougov-preferences">
        <header class="flex my-2">
            <div class="info-box ml-auto">
                <Icon
                    v-tooltip.left="{
                        content: ygPreferencesInfo,
                        classes: 'yg-preferences-info',
                    }"
                    name="icon-info"
                />
            </div>
        </header>
        <AutoCompleteList
            ref="autocomplete"
            name="welcome-flow"
            title="welcome flow"
            :label="`Welcome flow: ${selectedOptions.length}/${$options.maxOptions}`"
            :items="welcomeFlows"
            select-placeholder="Select a welcome flow"
            :selected-items="selectedOptions"
            :get-badge="$options.getBadge"
            @query="$emit('onGetConvoQuery', $event)"
            @update="onUpdate"
        >
            <template #selected-right>
                <span class="opacity-50"> ({{ percentage }}%) </span>
            </template>
        </AutoCompleteList>
    </section>
</template>

<script>
import AutoComplete from '@/components/forms/AutoComplete';
import Icon from '@/components/ui/Icon.vue';
import AutoCompleteList from '@/components/forms/AutoComplete/AutoCompleteList.vue';
import { ConvoTypes, BadgeType, YougovWelcomeFlowType } from '@/store/enums';

const MAX_OPTIONS = 4;

export default {
    name: 'YougovPreferences',
    components: {
        AutoCompleteList,
        Icon,
    },
    props: {
        showWelcomeChatPreference: {
            type: Boolean,
            required: true,
        },
        welcomeFlows: {
            type: Array,
            required: true,
        },
        preferences: {
            type: Object,
            required: true,
        },
    },

    computed: {
        selectedOptions() {
            return this.preferences.welcomeFlows || [];
        },
        percentage() {
            return ((1 / this.selectedOptions.length) * 100).toFixed(0);
        },
        autocompleteEnabled() {
            return this.selectedOptions.length < this.$options.maxOptions;
        },
    },
    created() {
        this.$options.maxOptions = MAX_OPTIONS;
        this.$options.getBadge = ({ type }) => {
            const chatBadge = {
                label: 'Welcome Chat',
                type: BadgeType.Info,
            };
            const surveyBadge = {
                label: 'Welcome Survey',
                type: BadgeType.Message,
            };
            const isSurveyType = [
                YougovWelcomeFlowType.welcomeSurvey,
                ConvoTypes.YougovWelcomeSurvey,
            ].includes(type);
            return isSurveyType ? surveyBadge : chatBadge;
        };
    },
    methods: {
        onUpdate(items) {
            const selectedOptions = items.map((item) => {
                const { id, name } = item;
                let type = item.type;
                if (!Object.values(YougovWelcomeFlowType).includes(type)) {
                    type =
                        type === ConvoTypes.YougovWelcomeSurvey
                            ? YougovWelcomeFlowType.welcomeSurvey
                            : YougovWelcomeFlowType.welcomeChat;
                }
                return { id, name, type };
            });
            this.$emit('onPreferencesUpdate', selectedOptions);
        },

        ygPreferencesInfo() {
            let options =
                `<li><span class='item-highlighted'>Welcome Survey:</span> Convo asks to opens an external tab where` +
                ` user is required to take the<br> yougov welcome survey.</li>`;
            if (this.showWelcomeChatPreference) {
                options +=
                    `<li><span class='item-highlighted'>Welcome Chat:</span> Convo containing a set of profile` +
                    ` questions.</li>`;
            }
            return (
                `<div style='line-height:2em'>This tab contains preferences related to convo/survey which will be` +
                ` shown at the end of taking<br> any convo from this channel. These convos will only be shown upon` +
                ` meeting certain criteria. It’s<br> not mandatory to provide a preference for any of these.<br>` +
                ` <em>We can select up to 4 of the following:</em>${options}</div>`
            );
        },
    },
};
</script>

<style lang="scss" scoped>
$yg-preferences-info-highlighted: #31caa8 !default;
.info-box {
    display: flex;
    justify-content: flex-end;
    margin-right: 1rem;
}
.item-highlighted {
    color: $yg-preferences-info-highlighted;
    font-weight: $font-weight-bold;
    font-style: italic;
}
</style>
