<template>
    <div class="convo-selector-convos m-l-2">
        <h3 class="m-b-1 font-bold">Selected and sorted convos</h3>

        <DraggableListGroup
            v-model="selectedConvos"
            group="convos"
            class="h-100"
            :class="{ 'convo-draggable-section': !canSchedule }"
        >
            <ListGroupItem v-for="(element, index) in selectedConvos" :key="element.name">
                {{ element.name }}

                <template v-slot:number>{{ index + 1 }}</template>

                <template v-slot:icon>
                    <IconButton
                        v-tooltip="`Move to top`"
                        icon="double-chevron"
                        class="double-chevron-up"
                        :disabled="index === 0"
                        @click="$emit('moveToTop', index)"
                    />

                    <IconButton
                        v-tooltip="`Move up`"
                        icon="chevron"
                        class="chevron-up"
                        :disabled="index === 0"
                        @click="$emit('moveUp', index)"
                    />

                    <IconButton
                        v-tooltip="`Move down`"
                        icon="chevron"
                        class="chevron"
                        :disabled="index === selectedConvos.length - 1"
                        @click="$emit('moveDown', index)"
                    />

                    <IconButton
                        v-tooltip="`Move to bottom`"
                        icon="double-chevron"
                        class="double-chevron"
                        :disabled="index === selectedConvos.length - 1"
                        @click="$emit('moveToBottom', index)"
                    />
                </template>
            </ListGroupItem>
        </DraggableListGroup>
    </div>
</template>

<script>
import { DraggableListGroup, ListGroupItem } from '@/components/ui/ListGroup/index';
import IconButton from '@/components/ui/IconButton.vue';

export default {
    name: 'SelectedConvoSection',
    components: {
        DraggableListGroup,
        ListGroupItem,
        IconButton,
    },
    props: {
        value: {
            type: Array,
            default: undefined,
        },
        canSchedule: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        selectedConvos: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
$list-group-item-border-radius: 4px;

.convo-selector-convos {
    width: 50%;
}

.convo-draggable-section {
    border: 1px dashed $input-border-color;
    height: 80%;
}

.double-chevron-up,
.chevron-up {
    transform: rotate(180deg);
}

/deep/ .icon-button {
    font-size: $font-size-sm;
}
</style>
