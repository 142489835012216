export default [
    {
        id: 0,
        value: 'Sunday',
    },
    {
        id: 1,
        value: 'Monday',
    },
    {
        id: 2,
        value: 'Tuesday',
    },
    {
        id: 3,
        value: 'Wednesday',
    },
    {
        id: 4,
        value: 'Thursday',
    },
    {
        id: 5,
        value: 'Friday',
    },
    {
        id: 6,
        value: 'Saturday',
    },
];
