<template>
    <section
        v-show="isActive"
        :id="computedId"
        :aria-hidden="!isActive"
        class="tabs-component-panel"
        role="tabpanel"
    >
        <slot />
    </section>
</template>

<script>
export default {
    name: 'STab',
    props: {
        name: {
            type: String,
            required: true,
        },
        prefix: {
            type: String,
            default: '',
        },
        suffix: {
            type: String,
            default: '',
        },
        isDisabled: {
            type: Boolean,
            default: false,
        },
        id: {
            type: String,
            default: null,
        },
    },
    data: () => ({
        isActive: false,
        isVisible: true,
    }),
    computed: {
        header() {
            return this.prefix + this.name + this.suffix;
        },
        computedId() {
            return this.id ? this.id : this.name.toLowerCase().replace(/ /g, '-');
        },
        hash() {
            if (this.isDisabled) {
                return '#';
            }
            return '#' + this.computedId;
        },
    },
};
</script>
