<template>
    <div>
        <Select
            v-model="localeModel"
            :options="$options.languageOptions"
            name="language-options"
            label="Language"
            placeholder="Select a language"
        />

        <table v-show="variables.length" class="variables-table" aria-label="email variables table">
            <thead>
                <tr>
                    <th id="heading-name" class="variable variable-heading variable-id">Name</th>
                    <th id="heading-value" class="variable variable-heading variable-value">
                        Value
                    </th>
                </tr>
            </thead>
            <tbody>
                <tr
                    v-for="(variable, index) in variables"
                    :key="`variable-${index}`"
                    class="variable"
                >
                    <td class="variable variable-id">{{ variable.id }}</td>
                    <td class="variable variable-value">{{ variable.value }}</td>
                </tr>
            </tbody>
        </table>

        <TimezoneSelector
            v-if="showTimezoneSelector"
            v-model="timezone"
            class="timezone-selector"
        />
    </div>
</template>

<script>
import { Select } from '@/components/forms';
import TimezoneSelector from '@/components/ui/TimezoneSelector';
import i18n from '@/i18n';
import config from '@/config';

export default {
    name: 'EmailVariables',
    components: {
        Select,
        TimezoneSelector,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    computed: {
        localeModel: {
            get() {
                return this.value;
            },
            set(locale) {
                this.$emit('input', locale);
            },
        },
        variables() {
            const locale = i18n[this.localeModel];
            if (!locale) {
                return [];
            }
            return Object.keys(locale.emailVariables).map((key) => ({
                id: key,
                value: locale.emailVariables[key],
            }));
        },
        showTimezoneSelector() {
            return config.featureToggle.enableAdvanceEmailAutomation;
        },
        timezone: {
            get() {
                return this.$store.state.channel.channelDetails.channel.timezoneIdentifier;
            },
            set(selectedTimezone) {
                this.$store.dispatch('channel/setTimezone', selectedTimezone);
            },
        },
    },
    created() {
        this.$options.languageOptions = Object.keys(i18n).map((key) => ({
            id: key,
            value: i18n[key].name,
        }));
    },
};
</script>

<style lang="scss" scoped>
.variables-table {
    border: 1px solid $input-border-color;
    border-collapse: separate;
    border-radius: 4px;
    width: 100%;
}
.variable-heading {
    text-align: left;
    font-weight: bold;
    border-bottom: 1px solid $input-border-color;
}
.variable-id {
    width: 30%;
}
.variable {
    padding: 8px;
    font-size: 14px;
}

.timezone-selector {
    width: 50%;
}
</style>
