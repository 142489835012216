import { IContentDiscoveryMenuItem } from '@/store/models/contentDiscoveryMenu/contentDiscoveryMenu.interface';

interface ITabNameResult {
    pointer: number;
    tabs: { tabName: string }[];
}

const getTabName = (pointer: number, count: number): string => {
    let tabName = `${pointer + 1}`;
    if (count > 1) {
        tabName += ` - ${pointer + count}`;
    }
    return tabName;
};

const tabsReducer = (result: ITabNameResult, item: IContentDiscoveryMenuItem) => {
    const count: number = item.convoLength || 1;
    result.tabs.push({ tabName: getTabName(result.pointer, count) });
    result.pointer += count;
    return result;
};

export const getTabNames = (items: IContentDiscoveryMenuItem[]): { tabName: string }[] => {
    const initial: ITabNameResult = { pointer: 0, tabs: [] };
    const result = items.reduce(tabsReducer, initial);
    return result.tabs;
};
