<template>
    <div v-if="showLandingPage" class="landing-page">
        <div class="landing-page__title">
            <Input
                :id="generateId('channel-title')"
                type="text"
                label="Channel Title"
                :name="generateId('channel-title')"
                :value="data.channelTitle"
                @input="(value) => $emit('propertyChange', { name: 'channelTitle', value })"
            />

            <Input
                :id="generateId('title')"
                type="text"
                label="Title"
                :name="generateId('title')"
                :value="data.title"
                @input="(value) => $emit('propertyChange', { name: 'title', value })"
            />
        </div>
        <div class="landing-page__description">
            <EditorInput
                label="Description"
                :content="data.description"
                @update="(value) => $emit('propertyChange', { name: 'description', value })"
            />
        </div>
    </div>
    <div v-else class="no-landing-page">
        <div class="no-landing-page__inner">
            <p class="m-b-2">
                There is currently no landing page available. Click below to create a new landing
                page.
            </p>
            <button class="add-button" @click="addLandingPage">
                <span class="add-button__icon">+</span> Add Landing Page
            </button>
        </div>
    </div>
</template>

<script>
import Input from '@/components/forms/Input';
import EditorInput from '@/components/forms/EditorInput';

export default {
    name: 'LandingPage',
    components: {
        Input,
        EditorInput,
    },
    props: {
        data: {
            type: Object,
            default: undefined,
        },
    },
    data() {
        return {
            hasLandingPage: false,
        };
    },
    computed: {
        showLandingPage() {
            return (
                this.data.channelTitle ||
                this.data.title ||
                this.data.description ||
                this.hasLandingPage
            );
        },
    },

    methods: {
        generateId(name, index) {
            return `landing-page-${name}`;
        },
        addLandingPage() {
            this.hasLandingPage = true;
        },
    },
};
</script>

<style lang="scss" scoped>
$landing-page-font-size: 14px;
$button-font-size: 12px;

.landing-page {
    display: flex;
    justify-content: space-between;

    &__title {
        flex: 0 0 50%;
        padding-right: 20px;
    }

    &__description {
        flex: 0 0 50%;
        max-width: 50%;
    }
}

.no-landing-page {
    display: flex;
    align-items: center;
    min-height: 120px;
    text-align: center;
    font-size: $landing-page-font-size;

    &__inner {
        width: 100%;
    }
}

.add-button {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 50px;
    width: 100%;
    padding: 5px 10px;
    border: 1px dashed $input-border-color;
    border-radius: 4px;
    font-family: $font-family-button;
    font-size: $button-font-size;
    color: $primary-text-button-text-color;
    text-transform: uppercase;
    text-align: center;
    cursor: pointer;

    &__icon {
        font-size: 22px;
        margin-right: 8px;
    }
}
</style>
