<template>
    <SCard class="card-menu">
        <header class="card-menu-header">
            <h4 class="card-menu-title">{{ name }}</h4>

            <IconWithTooltip v-if="isDefault" tooltip="Default Menu" icon-name="pin" />

            <Menu
                button-icon="actions-menu"
                class="card-menu-actions m-l-1"
                :disabled="menuActions.length === 0"
            >
                <DropDownItem
                    v-for="(menuAction, menuActionIdx) in menuActions"
                    :key="`card-menu-action-${menuActionIdx}`"
                    :icon="menuAction.icon"
                    :disabled="menuAction.disabled"
                    :disabled-text="menuAction.disabledText"
                    @click="$emit(menuAction.action)"
                >
                    {{ menuAction.label }}
                </DropDownItem>
            </Menu>
        </header>

        <p class="card-menu-statement">Statement: {{ statement }}</p>

        <div class="card-menu-button">
            <Button size="small" button-style="secondary" @click="$emit('click')">
                Edit menu
            </Button>
        </div>
    </SCard>
</template>

<script>
import SCard from '@/components/ui/SCard';
import IconWithTooltip from '@/components/ui/IconWithTooltip';
import Button from '@/components/ui/Button';
import DropDownItem from '@/components/ui/DropDown/DropDownItem';
import Menu from '@/components/ui/Menu';

export default {
    name: 'ContentDiscoveryListItem',
    components: {
        DropDownItem,
        Menu,
        SCard,
        IconWithTooltip,
        Button,
    },
    props: {
        name: {
            type: String,
            required: true,
        },
        statement: {
            type: String,
            required: true,
        },
        isDefault: {
            type: Boolean,
            required: true,
        },
        menuActions: {
            type: Array,
            default: () => [],
        },
    },
};
</script>

<style scoped lang="scss">
.card-menu {
    display: flex;
    flex-direction: column;
    min-height: 16rem;
}

.card-menu-header {
    display: flex;
    align-items: center;
    margin-bottom: 0.8rem;
}

.card-menu-title {
    margin-right: auto;
    text-transform: uppercase;
    font-size: $font-size-xs;
}

.card-menu-title {
    color: $color-java;
}

.card-menu-statement {
    font-size: $font-size-sm;
}

.card-menu-button {
    margin-top: auto;
    text-align: center;
}
</style>
