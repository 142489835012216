<template>
    <div class="schedule">
        <h3 class="m-b-1 font-bold">Schedule</h3>

        <template v-if="advanceEmailAutomationEnabled">
            <DayTimeOverlay
                :schedule="scheduledConfig"
                :show-overlay="showDateTimeOverlay"
                :timezone="timezone"
                @dayInput="onDayInput"
                @timeInput="onTimeInput"
                @removeItem="removeItem"
                @addDayTime="addDayTimeOption"
                @close="showDateTimeOverlay = false"
                @confirm="onConfirm"
            >
            </DayTimeOverlay>

            <div v-if="scheduledDates.length > 0" class="scheduled-dates-config m-b-1">
                <ul>
                    <li v-for="(scheduledDate, idx) in scheduledDates" :key="idx">
                        {{ scheduledDate }}
                    </li>
                </ul>
            </div>

            <div class="schedule-button">
                <Button
                    type="button"
                    button-style="second-accordion-level"
                    size="small"
                    @click="showDateTimeOverlay = true"
                >
                    + Edit/Add Date & Time
                </Button>
            </div>
        </template>
        <template v-else>
            <div class="schedule-time m-b-2">
                <Select
                    id="hour"
                    v-model="schedule.hour"
                    name="hour"
                    label="Hours"
                    :options="hourOptions"
                    :has-margin-bottom="false"
                />

                <span class="schedule-time-divider">:</span>

                <Select
                    id="minute"
                    v-model="schedule.minute"
                    name="minute"
                    label="Minutes"
                    :options="minuteOptions"
                    :has-margin-bottom="false"
                />
            </div>

            <div class="schedule-days m-b-2">
                <Checkbox
                    id="repeat-monday"
                    v-model="schedule.days"
                    input-value="MON"
                    label="Monday"
                />
                <Checkbox
                    id="repeat-tuesday"
                    v-model="schedule.days"
                    input-value="TUE"
                    label="Tuesday"
                />
                <Checkbox
                    id="repeat-wednesday"
                    v-model="schedule.days"
                    input-value="WED"
                    label="Wednesday"
                />
                <Checkbox
                    id="repeat-thursday"
                    v-model="schedule.days"
                    input-value="THU"
                    label="Thursday"
                />
                <Checkbox
                    id="repeat-friday"
                    v-model="schedule.days"
                    input-value="FRI"
                    label="Friday"
                />
                <Checkbox
                    id="repeat-saturday"
                    v-model="schedule.days"
                    input-value="SAT"
                    label="Saturday"
                />
                <Checkbox
                    id="repeat-sunday"
                    v-model="schedule.days"
                    input-value="SUN"
                    label="Sunday"
                />
            </div>
        </template>
    </div>
</template>

<script>
import dayOptions from '@/json/dayOptions';
import config from '@/config';
import { Checkbox, Select } from '@/components/forms/index';
import DayTimeOverlay from './DayTimeOverlay';
import Button from '@/components/ui/Button';

const DEFAULT_CRON = '* * * * *';

export default {
    name: 'EmailAutomationSchedule',
    components: {
        DayTimeOverlay,
        Button,
        Checkbox,
        Select,
    },
    props: {
        value: {
            type: String,
            default: DEFAULT_CRON,
        },
        scheduledConfig: {
            type: Array,
            default: () => [],
        },
        timezone: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            schedule: {
                hour: 0,
                minute: 0,
                days: [],
            },
            showDateTimeOverlay: false,
            scheduledDates: [],
        };
    },
    computed: {
        hourOptions() {
            return this.generateTimeOptions(24, 1);
        },
        minuteOptions() {
            return this.generateTimeOptions(60, 5);
        },
        cronDays() {
            return this.schedule.days.length ? Object.values(this.schedule.days).join(',') : '*';
        },
        advanceEmailAutomationEnabled() {
            return config.featureToggle.enableAdvanceEmailAutomation;
        },
    },
    watch: {
        schedule: {
            deep: true,
            handler(value) {
                this.$emit('input', this.makeCron());
            },
        },
        value: {
            immediate: true,
            handler(value) {
                const cron = value || DEFAULT_CRON;
                const parsed = this.parseCron(cron);
                this.setSchedule(parsed);
            },
        },
    },
    mounted() {
        this.getScheduledDates(this.scheduledConfig);
    },
    methods: {
        parseCron(cron) {
            const parts = cron.split(' ');
            const minute = !parts[0] || parts[0] === '*' ? 0 : Number(parts[0]);
            const hour = !parts[1] || parts[1] === '*' ? 0 : Number(parts[1]);
            const dayOfWeek = !parts[4] || parts[4] === '*' ? [] : parts[4].split(',');
            return { minute, hour, dayOfWeek };
        },
        setSchedule({ minute, hour, dayOfWeek }) {
            this.schedule.hour = hour;
            this.schedule.minute = minute;
            this.schedule.days = dayOfWeek;
        },
        makeCron() {
            return `${this.schedule.minute} ${this.schedule.hour} * * ${this.cronDays}`;
        },
        generateTimeOptions(total, increment) {
            const options = [];
            for (let i = 0; i < total; i += increment) {
                options.push({ id: i, value: i < 10 ? `0${i}` : i.toString() });
            }
            return options;
        },
        addDayTimeOption() {
            this.scheduledConfig.push({ day: '', time: '' });
        },
        removeItem(index) {
            this.scheduledConfig.splice(index, 1);
        },
        onDayInput({ value, index }) {
            this.scheduledConfig[index].day = value;
        },
        onTimeInput({ value, index }) {
            this.scheduledConfig[index].time = value;
        },
        onConfirm() {
            this.$emit('confirm', this.scheduledConfig);
            this.getScheduledDates(this.scheduledConfig);
        },

        getScheduledDates(selectedDates) {
            this.scheduledDates = [];
            for (let date of selectedDates) {
                const day = dayOptions.find((o) => o.id === date.day).value;
                this.scheduledDates.push(`Every ${day} at ${date.time}`);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.schedule-days,
.schedule-time {
    display: flex;
}

.schedule-days {
    flex-wrap: wrap;

    > div {
        margin-right: 16px;
    }
}

.schedule-time {
    align-items: center;
}

.schedule-time-divider {
    margin: 0 5px;
}

.schedule-forecast {
    ol {
        list-style-type: decimal;
        padding-left: 14px;
        font-size: 14px;
        line-height: 1.5;
    }
}
.scheduled-dates-config {
    display: flex;
    flex-direction: column;
    border: 1px solid $color-athens-gray;
    padding: 20px;

    li {
        font-size: 14px;
        color: $color-oxford-blue;
        line-height: 1.43;
        font-family: $font-family-default;
    }
}
.schedule-button {
    margin-top: auto;
    text-align: center;
}
</style>
