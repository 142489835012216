<template>
    <div class="list-group-item">
        <div v-if="hasNumberSlot" class="list-group-item-number">
            <slot name="number" />
        </div>

        <div class="list-group-item-content">
            <slot />

            <div v-if="hasDescriptionSlot" class="list-group-item-description">
                <slot name="description" />
            </div>
        </div>
        <div class="list-group-item-additional-information">
            <div v-if="hasTagSlot" class="list-group-item-tag">
                <slot name="tag" />
            </div>

            <div v-if="hasIconSlot" class="list-group-item-icon">
                <slot name="icon" />
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'ListGroupItem',
    props: {
        number: {
            type: Number,
            default: undefined,
        },
    },
    computed: {
        hasNumberSlot() {
            return !!this.$slots.number;
        },
        hasDescriptionSlot() {
            return !!this.$slots.description;
        },
        hasTagSlot() {
            return this.$slots.tag;
        },
        hasIconSlot() {
            return this.$slots.icon;
        },
    },
};
</script>

<style scoped lang="scss">
$list-group-item-border-radius: 4px;

.list-group-item {
    border: 1px solid $input-border-color;
    background-color: $list-group-item-background-color;
    margin-bottom: -1px;
    padding: 11px 12px;
    line-height: 1;
    display: flex;
    align-items: center;

    &:first-child {
        border-top-left-radius: $list-group-item-border-radius;
        border-top-right-radius: $list-group-item-border-radius;
    }

    &:last-child {
        border-bottom-left-radius: $list-group-item-border-radius;
        border-bottom-right-radius: $list-group-item-border-radius;
    }
}

.list-group-item-ghost {
    color: $list-group-item-ghost-color;
    background-color: $list-group-item-ghost-background-color;
}

.list-group-item-description {
    padding-top: 4px;
    font-size: 13px;
    opacity: 0.8;
}

.list-group-item-number {
    margin-right: 12px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-shrink: 0;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    font-size: $font-size-xs;
    background-color: $list-group-item-number-background-color;
    color: $list-group-item-number-color;
}

.list-group-item-additional-information {
    display: flex;
    margin-left: auto;
}

.list-group-item-icon {
    display: flex;
    margin-left: 10px;
}
</style>
