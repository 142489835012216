<template>
    <AutoComplete
        v-if="$options.timezoneList"
        v-model="autoComplete"
        class="m-t-2"
        :items="$options.timezoneList"
        label="Select timezone"
        placeholder="Search timezone..."
        :show-badge="false"
    />
</template>
<script>
import AutoComplete from '@/components/forms/AutoComplete';
import { timezoneList } from '@/json/timezones';

export default {
    name: 'TimezoneSelector',
    components: {
        AutoComplete,
    },
    props: {
        value: {
            type: String,
            default: '',
        },
    },
    computed: {
        autoComplete: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
    created() {
        this.$options.timezoneList = timezoneList;
    },
};
</script>
