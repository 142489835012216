<template>
    <div class="convo-selector-convos">
        <h3 class="m-b-1 font-bold">Available convos</h3>

        <DraggableListGroup v-model="availableConvos" group="convos">
            <ListGroupItem v-for="(element, index) in availableConvos" :key="element.name">
                {{ element.name }}

                <template v-if="element.tags.length > 0" v-slot:tag>
                    <Badge type="neutral">
                        {{ element.tags[0].name }}
                    </Badge>
                </template>

                <template v-slot:icon>
                    <IconButton
                        v-tooltip="`Add to top`"
                        icon="enter-arrow"
                        class="enter-arrow-up"
                        @click="$emit('moveToTop', index)"
                    />

                    <IconButton
                        v-tooltip="`Add to bottom`"
                        icon="enter-arrow"
                        class="enter-arrow-down"
                        @click="$emit('moveToBottom', index)"
                    />
                </template>
            </ListGroupItem>
        </DraggableListGroup>
    </div>
</template>

<script>
import { DraggableListGroup, ListGroupItem } from '@/components/ui/ListGroup/index';
import IconButton from '@/components/ui/IconButton.vue';
import Badge from '@/components/ui/Badge.vue';

export default {
    name: 'AvailableConvoSection',
    components: {
        DraggableListGroup,
        ListGroupItem,
        IconButton,
        Badge,
    },
    props: {
        value: {
            type: Array,
            default: undefined,
        },
    },
    computed: {
        availableConvos: {
            get() {
                return this.value;
            },
            set(value) {
                this.$emit('input', value);
            },
        },
    },
};
</script>

<style lang="scss" scoped>
$list-group-item-border-radius: 4px;

.convo-selector-convos {
    width: 50%;
}

.convo-draggable-section {
    border: 1px dashed $input-border-color;
    height: 80%;
}

.enter-arrow-up {
    transform: rotate(270deg) scaleX(-1);
}

.enter-arrow-down {
    transform: rotate(270deg);
}

/deep/ .icon-button {
    font-size: $font-size-sm;
}
</style>
