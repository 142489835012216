<template>
    <div class="day-time">
        <Select
            :id="`day-option`"
            :value="data.day"
            :errors="errors.day"
            class="day-option m-r-2"
            :options="dayOptions"
            label="Day"
            @input="(value) => $emit('dayInput', value)"
        >
        </Select>

        <Timepicker
            :id="`email-automation-time-picker`"
            :value="data.time"
            :errors="errors.time"
            class="time-option m-r-2 form-group"
            name="name"
            label="Time"
            @change="(value) => $emit('timeInput', value)"
        />

        <div class="option">
            <div v-tooltip.top="getTooltipText" class="info-icon-wrapper">
                <Icon :size="16" class="info-icon" name="icon-info" />
            </div>
            <IconButton
                v-tooltip.right="'Delete'"
                class="remove"
                icon="cross-simple"
                @click="$emit('remove')"
            />
        </div>
    </div>
</template>
<script>
import dayOptions from '@/json/dayOptions';
import { Select, Timepicker } from '@/components/forms/index';
import IconButton from '@/components/ui/IconButton';
import Icon from '@/components/ui/Icon';

export default {
    name: 'AutomationDayTimePicker',
    components: {
        Timepicker,
        IconButton,
        Select,
        Icon,
    },
    props: {
        data: {
            type: Object,
            default: () => ({ day: '', time: '' }),
        },
        timezone: {
            type: String,
            default: undefined,
        },
    },
    data: () => {
        return {
            dayOptions,
            errors: {},
        };
    },
    computed: {
        getTooltipText() {
            return this.timezone ? this.timezone : '';
        },
    },
    watch: {
        data: {
            deep: true,
            handler() {
                this.validate();
            },
        },
    },
    methods: {
        validate() {
            this.errors = {};
            if (this.data.day === '') {
                this.errors.day = ['Please enter a valid day'];
            }
            if (this.data.time === '') {
                this.errors.time = ['Plesae enter valid time'];
            }
            this.$emit('validate', this.errors);
        },
    },
};
</script>
<style lang="scss" scoped>
.day-time {
    display: flex;
}
.day-option {
    order: 0;
    width: 40%;
}
.time-option {
    order: 1;
    width: 40%;
    position: relative;
    top: 5px;
}
.option {
    order: 2;
    width: 20%;
    display: flex;
    align-self: center;
    position: relative;
    bottom: 14px;
}

/deep/ .icon-button {
    font-size: 14px;
}
/deep/ .icon {
    pointer-events: none;
}

.info-icon-wrapper {
    width: 25px;
    height: 25px;
    border-radius: 25px;
    margin-right: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 22px;
    color: $icon-button-text-color;
    transition: background-color 200ms, color 200ms;

    &:hover {
        color: $icon-button-text-hover-color;
        background: $icon-button-bg-hover-color;
    }
}
</style>
