<template>
    <div class="email-automation">
        <div v-if="isScheduled" class="m-b-2">
            <h3 class="m-b-1 font-bold">Scheduled convos</h3>

            <ListGroupItem v-for="(element, index) in model.selectedConvos" :key="element.name">
                {{ element.name }}
                <template v-slot:number>{{ index + 1 }}</template>
            </ListGroupItem>
        </div>

        <div v-if="!isScheduled">
            <div class="convo-selector m-b-2">
                <AvailableConvoSection
                    v-model="model.availableConvos"
                    @moveToTop="(idx) => moveToSelectedSection(idx, 0)"
                    @moveToBottom="(idx) => moveToSelectedSection(idx, model.selectedConvos.length)"
                />

                <SelectedConvoSection
                    v-model="model.selectedConvos"
                    :can-schedule="canSchedule"
                    @moveToTop="(idx) => reorderSelectedSectionItem(idx, 0)"
                    @moveUp="(idx) => reorderSelectedSectionItem(idx, idx - 1)"
                    @moveDown="(idx) => reorderSelectedSectionItem(idx, idx + 1)"
                    @moveToBottom="
                        (idx) => reorderSelectedSectionItem(idx, model.selectedConvos.length - 1)
                    "
                />
            </div>

            <EmailAutomationSchedule
                v-model="model.schedule"
                :scheduled-config="model.scheduledConfig"
                :timezone="timezone"
                class="m-b-2"
                @confirm="handleScheduledConfig"
            />
        </div>

        <div class="schduled-dates m-b-2">
            <h3 class="m-b-1 font-bold">Next 10 trigger dates</h3>
            <ol
                v-if="advanceEmailAutomationEnabled"
                :class="advanceEmailScheduledDates.length > 0 ? 'advance-schduled-dates-list' : ''"
            >
                <li v-for="(date, idx) in advanceEmailScheduledDates" :key="idx">
                    {{ date }}
                </li>
            </ol>
            <ol v-else class="schduled-dates-list">
                <li v-for="(date, idx) in scheduledDates" :key="idx">
                    {{ date }}
                </li>
            </ol>
        </div>

        <div class="buttons">
            <Button
                v-if="!isScheduled"
                class="cancel-button"
                size="small"
                button-style="secondary"
                @click="handleReset"
            >
                Reset
            </Button>

            <Button
                v-if="!isScheduled"
                type="button"
                has-border
                size="small"
                :disabled="!canSchedule"
                @click="scheduleDialogIsOpen = true"
            >
                Schedule
            </Button>

            <Button
                v-if="isScheduled"
                type="button"
                has-border
                size="small"
                @click="deleteDialogIsOpen = true"
            >
                Delete schedule
            </Button>
            <Dialog
                show-confirm-button
                :is-dialog-visible="scheduleDialogIsOpen"
                :is-loading="isLoading"
                @onClose="scheduleDialogIsOpen = false"
                @onClickOutside="scheduleDialogIsOpen = false"
                @confirmButtonOnClick="handleSchedule"
            >
                <template #header>
                    Some changes have been made to email automation schedule.
                </template>
                <template #body> Do you want to save these changes? </template>
            </Dialog>
            <Dialog
                show-confirm-button
                :is-dialog-visible="deleteDialogIsOpen"
                :is-loading="isLoading"
                confirm-button-text="Yes, Delete"
                @onClose="deleteDialogIsOpen = false"
                @onClickOutside="deleteDialogIsOpen = false"
                @confirmButtonOnClick="handleClear"
            >
                <template #header>
                    This will delete the existing email automation schedule.
                </template>
                <template #body> Do you want to delete the scheduled automation? </template>
            </Dialog>
        </div>
    </div>
</template>

<script>
import { ListGroupItem } from '@/components/ui/ListGroup/index';
import Button from '@/components/ui/Button';
import EmailAutomationSchedule from './EmailAutomationSchedule';
import CronParser from 'cron-parser';
import { EmailAutomationClient } from '@/api/email-automation';
import { ConvoScheduleRequest } from '@/store/models/dtos/convoScheduleRequests.dto';
import Dialog from '@/components/ui/Dialog';
import config from '@/config';
import AvailableConvoSection from './AvailableConvoSection.vue';
import SelectedConvoSection from './SelectedConvoSection.vue';

const SCHEDULED_DATES_COUNT = 10;

export default {
    name: 'EmailAutomation',
    components: {
        EmailAutomationSchedule,
        Button,
        Dialog,
        ListGroupItem,
        AvailableConvoSection,
        SelectedConvoSection,
    },
    props: {
        data: {
            type: Object,
            default: undefined,
        },
        timezone: {
            type: String,
            default: undefined,
        },
    },
    data() {
        const model = {
            schedule: this.data.schedule,
            selectedConvos: this.data.selectedConvos,
            availableConvos: this.data.availableConvos,
            scheduledConfig: this.data.scheduledConfig,
        };
        return {
            initial: JSON.parse(JSON.stringify(model)),
            model,
            isScheduled: this.data.selectedConvos && this.data.selectedConvos.length > 0,
            scheduleDialogIsOpen: false,
            deleteDialogIsOpen: false,
            isLoading: false,
        };
    },
    computed: {
        canSchedule() {
            if (this.advanceEmailAutomationEnabled) {
                return (
                    this.model.selectedConvos.length > 0 && this.model.scheduledConfig.length > 0
                );
            }
            return this.model.selectedConvos.length > 0;
        },
        advanceEmailScheduledDates() {
            const scheduledDates = [];
            const intervals = this.scheduledCronIntervals;

            if (intervals.length > 0) {
                while (scheduledDates.length < SCHEDULED_DATES_COUNT) {
                    scheduledDates.push(
                        ...intervals.map(
                            (o) => o && o.next()._date.toFormat("EEE MMM d yyyy HH:mm:ss 'GMT'ZZ'"),
                        ),
                    );
                }
            }

            return scheduledDates.splice(0, SCHEDULED_DATES_COUNT);
        },
        scheduledCronIntervals() {
            return this.model.scheduledConfig.map((date) => {
                const [hours, minutes] = date.time.split(':');
                if (hours && minutes) {
                    const cron = `${minutes} ${hours} * * ${date.day}`;
                    return CronParser.parseExpression(cron, {
                        tz: this.timezone || 'Europe/London',
                    });
                }
            });
        },
        scheduledDates() {
            const events = [];
            const cronParsed = CronParser.parseExpression(this.model.schedule, { utc: true });
            if (!cronParsed) {
                return events;
            }
            for (let i = 0; i < SCHEDULED_DATES_COUNT; i++) {
                const dateString = cronParsed.next().toDate().toUTCString();

                events.push(dateString);
            }

            return events;
        },

        advanceEmailAutomationEnabled() {
            return config.featureToggle.enableAdvanceEmailAutomation;
        },
    },
    methods: {
        handleReset() {
            Object.assign(this.model, this.initial);
        },
        async handleSchedule() {
            try {
                this.isLoading = true;
                const request = new ConvoScheduleRequest();
                const { schedule, selectedConvos, scheduledConfig } = this.model;
                request.convos = selectedConvos;
                request.schedule = schedule;
                request.scheduledConfig = scheduledConfig;

                await EmailAutomationClient.createConvoSchedule(
                    this.$route.params.channelCode,
                    request,
                );

                this.isScheduled = true;
            } catch (error) {
                this.$log.error(error);
            } finally {
                this.isLoading = false;
                this.scheduleDialogIsOpen = false;
            }
        },
        async handleClear() {
            try {
                this.isLoading = true;
                await EmailAutomationClient.deleteConvoSchedule(this.$route.params.channelCode);
                this.isScheduled = false;
            } catch (error) {
                this.$log.error(error);
            } finally {
                this.isLoading = false;
                this.deleteDialogIsOpen = false;
            }
        },
        handleScheduledConfig(scheduledConfig) {
            this.model.scheduledConfig = scheduledConfig;
            this.$store.dispatch('channel/setConvoSchedule', this.model);
        },
        moveToSelectedSection(from, to) {
            const reorderedItem = this.reorderItem(
                this.model.availableConvos,
                from,
                to,
                this.model.selectedConvos,
            );
            this.model.selectedConvos = reorderedItem[0];
            this.model.availableConvos = reorderedItem[1];
        },
        reorderSelectedSectionItem(from, to) {
            const reorderedItem = this.reorderItem(this.model.selectedConvos, from, to);
            this.model.selectedConvos = reorderedItem[0];
        },
        reorderItem(source, from, to, target = source) {
            const sourceClonedArr = [...source];
            const targetClonedArr = [...target];

            let movedItem;
            if (target === source) {
                movedItem = targetClonedArr.splice(from, 1)[0];
            } else {
                movedItem = sourceClonedArr.splice(from, 1)[0];
            }

            targetClonedArr.splice(to, 0, movedItem);

            return [targetClonedArr, sourceClonedArr];
        },
    },
};
</script>

<style lang="scss" scoped>
$list-group-item-border-radius: 4px;

.convo-selector {
    display: flex;
}

.buttons {
    display: flex;
    align-items: center;
    justify-content: center;
}

.schduled-dates-list {
    font-size: $font-size-sm;
    opacity: 0.8;
    list-style: inside;
    list-style-type: decimal;
}

.advance-schduled-dates-list {
    font-size: $font-size-sm;
    list-style: inside;
    list-style-type: decimal;
    display: flex;
    flex-direction: column;
    border: 1px solid $color-athens-gray;
    padding: 20px;
    background-color: $color-gray-96;
    height: auto;

    @include media-query(large) {
        flex-wrap: wrap;
        height: 150px;
    }

    li {
        color: $color-oxford-blue;
        line-height: 1.43;
        font-family: $font-family-default;

        &:before {
            content: '';
            margin-left: 5px;
        }
    }
}
</style>
