<template>
    <FormOverlay
        title="Day & Time"
        confirm-button-text="Done"
        :confirm-button-disabled="confirmButtonDisabled"
        :show-overlay="showOverlay"
        @close="$emit('close')"
        @confirm="onConfirm"
    >
        <AutomationDaytimePicker
            v-for="(option, index) in schedule"
            :key="index"
            :data="option"
            :timezone="timezone"
            @dayInput="(value) => $emit('dayInput', { value, index })"
            @timeInput="(value) => $emit('timeInput', { value, index })"
            @remove="() => $emit('removeItem', index)"
            @validate="onValidate"
        ></AutomationDaytimePicker>

        <Button
            type="button"
            button-style="second-accordion-level"
            size="small"
            @click="$emit('addDayTime')"
        >
            + ADD MORE
        </Button>
    </FormOverlay>
</template>
<script>
import FormOverlay from '@/components/ui/FormOverlay';
import AutomationDaytimePicker from './AutomationDayTimePicker';
import Button from '@/components/ui/Button';

export default {
    name: 'DayTimeOverlay',
    components: {
        AutomationDaytimePicker,
        FormOverlay,
        Button,
    },
    props: {
        showOverlay: {
            type: Boolean,
            required: true,
        },
        schedule: {
            type: Array,
            default: () => [],
        },
        timezone: {
            type: String,
            default: undefined,
        },
    },
    data() {
        return {
            confirmButtonDisabled: true,
        };
    },
    methods: {
        onConfirm() {
            this.$emit('confirm');
            this.$emit('close');
        },
        onValidate(errors) {
            this.confirmButtonDisabled = true;
            if (Object.keys(errors).length === 0) {
                this.confirmButtonDisabled = false;
            }
        },
    },
};
</script>
