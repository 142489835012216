<template>
    <Tabset
        add-button-text="<span>Add <span class='hide-for-small-only'>Item</span> +</span>"
        :tabs="menuItemTabs"
        :errors="[]"
        :tabs-max-count="10"
        :is-last-tab-pinned="false"
        :show-pin-action="false"
        :selected-tab="selectedTab"
        tab-item-name="item"
        @addTab="addTab"
        @deleteTab="(index) => $emit('delete-item', index)"
        @reorderTabs="$emit('reorder-items', $event)"
        @pinTab="(index, value) => $emit('property-change', { name: 'isLastTileShuffled', value })"
    >
        <template #header>
            <div class="content-discovery-menu-header">
                <div class="content-discovery-menu-header__left">
                    <ErrorMessage v-if="Object.keys(errors).length" size="large" class="m-b-2">
                        Incomplete fields
                    </ErrorMessage>

                    <Input
                        :id="generateId('title')"
                        type="text"
                        label="Title"
                        show-remaining-char
                        placeholder="Enter internal title for menu"
                        :name="generateId('title')"
                        :max="80"
                        :value="menu.name"
                        :errors="errors.name"
                        @input="onInput('name', $event)"
                    />

                    <Input
                        :id="generateId('statement')"
                        type="text"
                        label="Statement"
                        placeholder="Enter statement"
                        show-remaining-char
                        :name="generateId('statement')"
                        :max="80"
                        :value="menu.statement"
                        :errors="errors.statement"
                        @input="onInput('statement', $event)"
                    />
                </div>
                <div class="content-discovery-menu-header__right">
                    <Toggle
                        v-if="showDefaultButton"
                        id="content-discovery-default"
                        label="Default"
                        :value="menu.default"
                        :disabled="true"
                        @input="onInput('default', $event)"
                    />
                </div>
            </div>
        </template>

        <template v-slot:default="{ data: menuItem, index }">
            <ContentDiscoveryMenuBuilder
                :data="menuItems[index]"
                :errors="errors.items && errors.items[index]"
                @input-menu-type="$emit('input-menu-type', { menuType: $event, index })"
                @input-menu-item-property="$emit('input-menu-item-property', { ...$event, index })"
            />
        </template>
    </Tabset>
</template>

<script>
import Tabset from '@/components/ui/Tabset';
import { Input, Toggle } from '@/components/forms';
import ContentDiscoveryMenuBuilder from './ContentDiscoveryMenuBuilder';
import ErrorMessage from '@/components/ui/ErrorMessage';
import { getTabNames } from './tabNameHelper';
import config from '@/config';

export default {
    name: 'ContentDiscoveryMenu',
    components: {
        Tabset,
        Input,
        Toggle,
        ErrorMessage,
        ContentDiscoveryMenuBuilder,
    },
    props: {
        menu: {
            type: Object,
            default: () => ({}),
        },
        menuItems: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: Object,
            default: () => ({}),
        },
    },
    data: () => {
        return {
            tabAdded: false,
        };
    },
    computed: {
        menuItemTabs() {
            return getTabNames(this.menuItems);
        },
        showDefaultButton() {
            return config.featureToggle.contentDiscoveryMultipleMenus;
        },
        selectedTab() {
            return this.tabAdded ? this.menuItems.length - 1 : 0;
        },
    },
    methods: {
        generateId(name, index) {
            return `content-discovery-menu-item-${name}-${index}`;
        },
        onInput(key, value) {
            this.$emit('input-menu-property', { key, value });
        },
        addTab() {
            this.tabAdded = true;
            this.$emit('add-item');
        },
    },
};
</script>

<style lang="scss" scoped>
.content-discovery-menu-header {
    margin-bottom: 20px;
    display: flex;

    &__left {
        flex-grow: 1;
        padding-right: 16px;
    }

    &__right {
        padding-top: 5px;
        height: 41px;
        display: flex;
        align-items: center;
    }
}
</style>
