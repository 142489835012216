import {
    IConvoScheduleDto,
    IScheduledConfig,
    IScheduledConvo,
} from '../interfaces/convoSchedule.interface';

export class ConvoScheduleRequest implements IConvoScheduleDto {
    schedule: string;
    convos: IScheduledConvo[];
    scheduledConfig: IScheduledConfig[];
}
