<template>
    <div v-tooltip="tooltip" class="icon-tooltip">
        <Icon :name="iconName" :size="iconSize" class="icon-tooltip-icon" />
    </div>
</template>

<script>
import Icon from '@/components/ui/Icon';

export default {
    name: 'IconWithTooltip',
    components: {
        Icon,
    },
    props: {
        tooltip: {
            type: String,
            required: true,
        },
        iconName: {
            type: String,
            required: true,
        },
        iconSize: {
            type: Number,
            default: 20,
        },
    },
};
</script>

<style lang="scss" scoped>
.icon-tooltip {
    color: $color-java;
}

.icon-tooltip-icon {
    pointer-events: none;
}
</style>
