<template>
    <div class="content-discovery-list">
        <div class="content-discovery-list-items">
            <p v-if="list.length === 0" class="text-center m-b-2 w-100">
                No discovery menus created yet.
            </p>

            <div v-for="item in list" :key="item.id" class="content-discovery-list-item">
                <ContentDiscoveryListItem
                    :name="item.name"
                    :statement="item.statement"
                    :is-default="item.default"
                    :menu-actions="getListItemMenuActions(item.default)"
                    @delete="onDeleteMenu(item.id)"
                    @set-default="onSetDefaultMenu(item)"
                    @click="$emit('click-menu', item.id)"
                />
            </div>
        </div>

        <Button
            v-if="canAddMenu"
            button-style="primary"
            has-border
            size="small"
            class="button-create-menu"
            @click="$emit('create-new-menu')"
        >
            Add new menu
        </Button>

        <Dialog
            show-confirm-button
            :is-dialog-visible="showDialog"
            confirm-button-text="Yes, delete menu"
            @onClose="dialogCancel"
            @onClickOutside="dialogCancel"
            @confirmButtonOnClick="dialogConfirm"
        >
            <template #header> This will delete this menu. </template>
            <template #body> Are you sure? </template>
        </Dialog>
    </div>
</template>

<script>
import ContentDiscoveryListItem from './ContentDiscoveryListItem';
import Button from '@/components/ui/Button';
import Dialog from '@/components/ui/Dialog';

export default {
    name: 'ContentDiscoveryList',
    components: {
        ContentDiscoveryListItem,
        Button,
        Dialog,
    },
    props: {
        canAddMenu: {
            type: Boolean,
            default: true,
        },
        list: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            showDialog: false,
        };
    },
    methods: {
        onDeleteMenu(menuId) {
            this.emitDeleteMenuFn = () => this.$emit('delete-menu', menuId);
            this.showDialog = true;
        },
        onSetDefaultMenu(menu) {
            this.$emit('set-default-menu', menu);
        },
        dialogConfirm() {
            this.emitDeleteMenuFn();
            this.showDialog = false;
        },
        dialogCancel() {
            this.showDialog = false;
        },
        getListItemMenuActions(isDefault) {
            return [
                {
                    icon: 'pin',
                    action: 'set-default',
                    label: 'Set as default',
                    disabled: isDefault,
                    disabledText: 'This menu is already the default menu',
                },
                {
                    icon: 'delete-full',
                    action: 'delete',
                    label: 'Delete menu',
                    disabled: isDefault,
                    disabledText: 'The default menu cannot be deleted',
                },
            ];
        },
    },
};
</script>

<style scoped lang="scss">
.button-create-menu {
    margin: 0 auto;
    display: block;
}

.content-discovery-list-items {
    margin: 0 -10px;

    @include media-query(large) {
        display: flex;
        flex-wrap: wrap;
    }
}

.content-discovery-list-item {
    margin-bottom: 20px;
    padding: 0 10px;

    @include media-query(large) {
        width: 50%;
    }
}
</style>
