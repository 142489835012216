<template>
    <div class="page-container">
        <router-view :key="$route.path" :nav-is-opened="navIsOpened"></router-view>

        <FullPageLoadingSpinner :show="showSpinner" />

        <PageHeading :breadcrumbs="breadcrumbs" :title="`${isNew ? 'Add' : 'Edit'} channel`" />

        <ChannelCard
            v-if="isLoaded"
            ref="channelCard"
            class="m-b-2"
            :channel-details="channelDetails"
            :categories="categories"
            :platforms="platforms"
            :tags="tags"
            :channel-is-valid="channelIsValid"
            :channel-model-has-changes="hasUnsavedChanges"
            :nav-is-opened="navIsOpened"
            :is-saving="$wait.is(LoadingFlag.ChannelSave)"
            :confirm-dialog-is-open="confirmDialogIsOpen"
            :topic-search-result="topicSearchResult"
            @autocomplete="($event) => getTags({ value: $event, type: 'channel' })"
            @change="updateChannelProperty"
            @confirmDialogSaveButtonClick="dialogSaveButtonClick"
            @confirmDialogOnClose="confirmDialogIsOpen = false"
            @setupFacebook="setupFacebookClick"
            @landingPagePropertyChange="updateLandingPageProperty"
            @onGetConvoQuery="onGetConvoQuery"
            @onUpdateYougovPreferences="updateYougovPreferencesProperty"
            @onSearchTopic="onSearchTopic"
        />

        <Stepper
            show-next-button
            show-back-button
            show-buttons-slot
            next-button-help=""
            :next-button-text="hasUnsavedChanges ? 'Save changes' : 'Finish'"
            :nav-is-opened="navIsOpened"
            :is-loading="false"
            @backButtonOnClick="goToOverview"
            @nextButtonOnClick="confirmButtonClick"
        >
            <template v-slot:buttons>
                <SaveStatus :save-time="saveTime" :is-saved="!hasUnsavedChanges" />
            </template>
        </Stepper>

        <Dialog
            show-confirm-button
            confirm-button-text="Discard changes"
            close-button-text="Continue Editing"
            :is-dialog-visible="leaveDialogIsOpen"
            @onClose="leaveDialogOnConfirm"
            @onClickOutside="leaveDialogOnConfirm"
            @closeOnEscapeEvent="leaveDialogOnConfirm"
            @confirmButtonOnClick="leaveDialogOnClose"
        >
            <template #header>There are unsaved changes made.</template>
            <template #body>Continue editing to save progress.</template>
        </Dialog>
    </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import { Breadcrumb } from '@/store/models/breadcrumb.dto.ts';
import ChannelCard from '@/components/ChannelCard';
import FullPageLoadingSpinner from '@/components/ui/FullPageLoadingSpinner';
import { LoadingFlag } from '@/store/enums/loadingIds.enum.ts';
import PageHeading from '@/components/ui/PageHeading';
import Stepper from '@/components/ui/Stepper';
import SaveStatus from '@/components/ui/SaveStatus';
import { TopicClient } from '@/api/topic';
import LeavingDialogMixin from '@/mixins/leavingDialogMixin';
import Dialog from '@/components/ui/Dialog';

export default {
    name: 'ChannelDetails',
    components: {
        Stepper,
        SaveStatus,
        ChannelCard,
        PageHeading,
        FullPageLoadingSpinner,
        Dialog,
    },
    mixins: [LeavingDialogMixin],
    props: {
        navIsOpened: {
            type: Boolean,
            required: true,
        },
    },
    data() {
        return {
            LoadingFlag,
            breadcrumbs: [],
            confirmDialogIsOpen: false,
            isLoaded: false,
            topicSearchResult: [],
        };
    },
    computed: {
        ...mapGetters('main', {
            isLoading: 'isLoading',
        }),
        ...mapGetters('tenant', {
            categories: 'categories',
            platforms: 'platforms',
            tags: 'tags',
        }),
        ...mapGetters('channel', {
            channelListView: 'channelListView',
            channelDetails: 'channelDetails',
            hasUnsavedChanges: 'channelModelHasChanges',
            channelIsValid: 'channelIsValid',
        }),
        isNew() {
            return this.$route.params.channelCode === undefined;
        },
        showSpinner() {
            return !this.isLoaded || this.$wait.is(LoadingFlag.ChannelGetOne);
        },
        saveTime() {
            return this.channelDetails.channel.id
                ? new Date(this.channelDetails.channel.updatedAt)
                : undefined;
        },
    },
    async created() {
        // Disable getCategories call for now.
        // Might be added back in the future.
        // await this.getCategories();
        await this.getPlatforms();

        if (this.isNew) {
            this.setDefaultState();
        } else {
            await this.setChannelDetails();
            await this.getChannelTenant(this.channelDetails.channel.id);
        }

        this.isLoaded = true;
        this.setBreadcrumbs();
        this.clearWelcomeFlows();
    },
    async beforeRouteLeave(to, from, next) {
        this.resetChannelDetails();
        next();
    },
    methods: {
        ...mapActions('channel', {
            setDefaultState: 'setDefaultState',
            getChannels: 'getChannels',
            setChannelDetails: 'setChannelDetails',
            updateChannelProperty: 'updateChannelProperty',
            saveChannel: 'saveChannel',
            validateChannel: 'validateChannel',
            setupFacebook: 'setupFacebook',
            updateLandingPageProperty: 'updateLandingPageProperty',
            updateYougovPreferencesProperty: 'updateYougovPreferencesProperty',
            getChannelTenant: 'getChannelTenant',
            resetChannelDetails: 'resetChannelDetails',
        }),
        ...mapActions('tenant', {
            getCategories: 'getCategories',
            getPlatforms: 'getPlatforms',
            getTags: 'getTags',
        }),
        ...mapActions('convoList', {
            getWelcomeFlows: 'getWelcomeFlows',
            clearWelcomeFlows: 'clearWelcomeFlows',
        }),
        setupFacebookClick: async function () {
            await this.setupFacebook();
        },
        setBreadcrumbs() {
            const breadcrumbs = [];

            breadcrumbs.push(new Breadcrumb('channels', { name: 'all-channels' }));

            if (this.channelDetails.channel.id) {
                breadcrumbs.push(
                    new Breadcrumb(this.channelDetails.channel.name, {
                        name: 'convo-list',
                        channelId: this.channelDetails.channel.id,
                    }),
                );
            }
            this.breadcrumbs = breadcrumbs;
        },
        confirmButtonClick: async function () {
            await this.validateChannel();

            if (!this.channelIsValid) {
                this.$refs.channelCard.animateErrorMessage();
                return;
            }

            if (this.hasUnsavedChanges) {
                this.confirmDialogIsOpen = true;
            } else {
                this.goToOverview();
            }
        },
        cancelButtonClick: async function () {
            await this.setChannelDetails();
        },
        dialogSaveButtonClick: async function () {
            await this.saveChannel();
            this.confirmDialogIsOpen = false;

            if (this.isNew) {
                this.$nextTick(() => {
                    this.$router.push({
                        name: 'channel-detail',
                        params: { channelCode: this.channelDetails.channel.code },
                    });
                });
            }
        },
        goToOverview: function () {
            this.$router.push({ name: 'all-channels' });
        },
        onGetConvoQuery(query) {
            this.getWelcomeFlows({
                q: query,
            });
        },
        async onSearchTopic(query) {
            const topics = await new TopicClient().fetch({
                namespace: 'global',
                q: query,
                size: 15,
            });
            this.topicSearchResult = topics?.items || [];
        },
    },
};
</script>
